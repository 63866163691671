<template>
  <ActivationsCard :title="$t('services.serviceActivations.selectionGuideManagement.title')">
    <div>
      <v-row>
        <v-col class="flex-grow-0 pt-3">
          <v-switch inset
            ref="is-visible-in-selection-guide-slider"
            v-model="isVisibleInSelectionGuide"
            :disabled="!isAuthorized || isPublishDisabled"
            @change="updateIsPublishedAsync"
          />
        </v-col>
        <v-col>
          <h3>{{ $t('services.serviceActivations.selectionGuideManagement.visible.title') }}</h3>
          <p>{{ $t('services.serviceActivations.selectionGuideManagement.visible.description') }}</p>
          <p class="error-message" v-if="isAuthorized && isPublishDisabled">{{ $t('services.serviceActivations.selectionGuideManagement.visible.errorText') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="flex-grow-0 pt-3">
          <v-switch inset
            ref="is-bookable-in-selection-guide-slider"
            v-model="isBookableInSelectionGuide"
            :disabled="!isAuthorized || isBookableSliderDisabled"
            @change="updateIsBookableAsync"
          />
        </v-col>
        <v-col>
          <h3>{{ $t('services.serviceActivations.selectionGuideManagement.active.title') }}</h3>
          <p>{{ $t('services.serviceActivations.selectionGuideManagement.active.description') }}</p>
          <p class="error-message" v-if="isAuthorized && isBookableSliderDisabled">{{ $t('services.serviceActivations.selectionGuideManagement.active.errorText') }}</p>
        </v-col>
      </v-row>
    </div>
  </ActivationsCard>
</template>

<script lang="ts">
import { Service, ServiceProduct, SubscriptionService } from '@/models'
import { Vue, Component, Prop } from 'vue-property-decorator'
import ActivationsCard from './ActivationsCard.vue'
import { isBetween } from '@/utils/dateUtils'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { IOfferService } from '@/services/OfferService/IOfferService'
import { IAuthService } from '@/services/AuthService/IAuthService'
import Permission from '@/models/enums/Permission'

@Component({
  components: { ActivationsCard }
})
export default class SelectionGuideActivation extends Vue {
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  offerService: IOfferService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)

  @Prop({ required: true })
  public value!: Service

  @Prop({ required: false })
  activeProducts!: ServiceProduct[]

  @Prop({ required: false })
  activeSubscriptions!: SubscriptionService[]

  get service (): Service {
    return this.value
  }

  get useFlexkidsProducts (): boolean {
    return this.service?.serviceSettings[0]?.useFlexkidsProducts
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.PublishService])
  }

  get isVisibleInSelectionGuide (): boolean {
    return this.service?.isPublished
  }

  set isVisibleInSelectionGuide (isEnabled: boolean) {
    if (this.service) {
      this.service.isPublished = isEnabled
    }
  }

  get isPublishDisabled (): boolean {
    // The service must have a description and at least one media item attached in order to be publishable.
    return !this.service?.description?.length || !this.service?.serviceMedia?.length
  }

  get isBookableInSelectionGuide (): boolean {
    return this.service?.isBookable
  }

  set isBookableInSelectionGuide (isEnabled: boolean) {
    if (this.service) {
      this.service.isBookable = isEnabled
    }
  }

  get isBookableSliderDisabled (): boolean {
    // If the service uses Flexkids products then there has to be at least one active product.
    if (this.useFlexkidsProducts) {
      return !this.activeProducts?.length
    }

    // For subscription based service there must be a valid permitNumber, a price that is active given the current date
    // and there must be at least one active subscription.
    const now = new Date()
    const hasActivePrice = this.service?.servicePricings.some(x => isBetween(now, x.validFrom, x.validUntil))
    if (!hasActivePrice || this.activeSubscriptions.length < 1) {
      return true
    }

    return false
  }

  async updateIsPublishedAsync (): Promise<void> {
    await this.commitChangesAsync()
    this.$emit('input', this.service)
  }

  async updateIsBookableAsync (): Promise<void> {
    await this.commitChangesAsync()

    // If IsBookable is enabled then CapacityChangedOn needs to be set so that the capacities will be calculated again.
    if (this.isBookableInSelectionGuide) {
      await this.offerService.updateCapacityChangedOnForOfferByServiceIdAsync(this.service.id)
    }

    this.$emit('input', this.service)
  }

  async commitChangesAsync (): Promise<void> {
    await this.serviceService.updateServiceIsPublishedAsync(
      this.service.id,
      this.service.isPublished,
      this.service.isPublishedForChildBenefitCalculator,
      this.service.isBookable
    )
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/switch.scss';

p {
  color: $partou-primary-black-seventy;
}
</style>

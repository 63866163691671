<template>
  <div v-if="value.changeDetails">
    <h3>{{ $t(`pages.audit.table.auditDetails.product.itemHeading${value.type}`) }}</h3>
    <v-row>
      <v-col>
        <p class="item-detail minor-heading">{{ $t(`pages.audit.table.auditDetails.product.productAndServiceVarietyHeading`) }}</p>
        <p class="item-detail">{{ value.changeDetails.product }}</p>
        <p class="item-detail">{{ value.changeDetails.serviceVariety }}</p>
      </v-col>
      <v-col v-if="showProductNote" ref="product-note">
        <p class="item-detail minor-heading">
          <v-icon class="note-visibility-icon" ref="icon-visible" v-if="value.changeDetails.showTextualAddition">$vuetify.icons.PartouVisibility</v-icon>
          <v-icon class="note-visibility-icon" ref="icon-not-visible" v-else>$vuetify.icons.PartouVisibilityOff</v-icon>
          {{ $t(`pages.audit.table.auditDetails.product.noteHeading`) }}
        </p>
        <p class="item-detail">
          <span class="bold-item" v-if="showProductNoteChange">{{ $t(`pages.audit.table.auditDetails.product.noteNew`) }} </span>
          <span>{{ value.changeDetails.textualAddition }}</span>
        </p>
        <p class="item-detail" v-if="showProductNoteChange" ref="product-note-old">
          <span class="bold-item">{{ $t(`pages.audit.table.auditDetails.product.noteOld`) }} </span>
          <span>{{ value.changeDetails.textualAdditionOld}}</span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ServiceAuditItem, ServiceAuditType } from '@/models/Audit'
import PartouVisibility from '@/components/PartouComponents/Icons/PartouVisibility.vue'
import PartouVisibilityOff from '@/components/PartouComponents/Icons/PartouVisibilityOff.vue'

@Component({
  components: { PartouVisibility, PartouVisibilityOff }
})
export default class ServiceAuditProductItem extends Vue {
  @Prop({ required: true })
  value!: ServiceAuditItem

  get showProductNote (): boolean {
    return [ServiceAuditType.ProductNoteActivated, ServiceAuditType.ProductNoteDeactivated, ServiceAuditType.ProductNoteChanged].includes(this.value.type)
  }

  get showProductNoteChange (): boolean {
    return this.value.type === ServiceAuditType.ProductNoteChanged
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

h3 {
  color: $partou-primary-black-eighty !important;
}

.col {
  padding-top: 8px;
  padding-bottom: 0px;
}

.item-detail {
  margin-bottom: 8px !important;
  color: $partou-primary-black-eighty;
}

.note-visibility-icon {
  color: $partou-primary-salmon-fourty;
  width: 16px;
  height: 10px;
}

.minor-heading {
  display: inline-block;
  padding-bottom: 4px;
  color: $partou-primary-black-seventy;
  border-bottom: solid 1px $partou-primary-salmon-fourty;
}

.bold-item {
  color: $partou-primary-black-ninety;
  font-weight: bold;
}
</style>

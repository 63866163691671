import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { ISendEmailService } from './ISendEmailService'
import Permission from '@/models/enums/Permission'
import { SendEmailsDocument, SendEmailsMutation, SendWaitingListActualisationEmailsDocument, SendWaitingListActualisationEmailsMutation } from '@/models'

@injectable()
export default class SendEmailService implements ISendEmailService {
  private _apollo: ApolloClient<unknown>

  public constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this._apollo = apollo
  }

  public async triggerSendEmailsAsync (): Promise<boolean> {
    let returnValue = false
    await this._apollo.mutate<SendEmailsMutation>({
      mutation: SendEmailsDocument,
      context: { headers: { 'X-Hasura-Role': Permission.CallActions } }
    }).then(() => {
      returnValue = true
    }).catch(() => {
      returnValue = false
    })

    return returnValue
  }

  public async triggerSendWaitingListActualisationEmailsAsync (): Promise<boolean> {
    let returnValue = false
    await this._apollo.mutate<SendWaitingListActualisationEmailsMutation>({
      mutation: SendWaitingListActualisationEmailsDocument,
      context: { headers: { 'X-Hasura-Role': Permission.CallActions } }
    }).then(result => {
      returnValue = result.data?.sendWaitingListActualisationEmails?.allMailsWereSentSuccessfully ?? false
    }).catch(() => {
      returnValue = false
    })

    return returnValue
  }
}

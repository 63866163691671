<template>
  <PartouTextField
    v-model="searchTerm"
    @input="onSearchTermChanged"
    @keydown.enter="onBlur"
    @blur="onBlur"
    :label="placeholder"
    class="search"
    :iconName="'$vuetify.icons.partouSearch'"
    :iconPosition="'right'"
  />
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'

@Component({
  components: { PartouTextField }
})
export default class SettingsPage extends Vue {
  @Prop({ required: false, default: '' })
  value! : string

  @Prop({ required: false, default: i18n.t('searchField.placeholder') })
  placeholder! : string

  searchTerm: string = this.value

  @Watch('value', { immediate: true })
  onSearchTermChanged (newValue: string): void {
    this.searchTerm = newValue
  }

  onBlur (): void {
    this.$emit('input', this.searchTerm)
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';
.search {
  width: 300px;
  padding-top: 0 !important;

  .v-input__control .v-input__slot {
    background: $partou-white !important;
    border: none !important;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  }

  .v-input__slot::before {
    display:none;
  }
  .v-input__slot::after {
    display:none;
  }
  .v-text-field__details {
    display: none;
  }
  .v-text-field__slot {
    display: none;
  }
}
</style>

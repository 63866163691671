<template>
  <v-tabs class="tabs-container" v-model="selectedTabIndex" fixed-tabs :show-arrows="false">
    <v-tab class="day-wrapper" v-for="(day, index) in weekData" :key="index"
      v-bind:class="{ divider: index !== weekData.length - 1 }"
      @click="onWeekDayClicked(day.date)">

      <div class="category-wrapper" v-for="categoryData in day.ageCategoryHeaderSummaryData" :key="categoryData.ageCategory">
        <div class="category-waiting-list">
          <span v-if="categoryData.waitingList > 0">{{ categoryData.waitingList }}<v-icon>mdi-format-list-numbered</v-icon></span>
        </div>
        <div class="category-number" v-bind:class="{ 'text-green': categoryData.availableSeats > 0 }">{{ categoryData.availableSeats }}</div>
        <div class="category-text">{{ $t(`pages.ServiceOffer.ageCategory.${categoryData.ageCategory}`) }}</div>
      </div>

      <div class="day-tab">
        <h2 class="day-tab-text">{{ $t(`pages.ServiceOffer.day.${day.dayIndex}`) }}</h2>
        <p class="day-tab-date">{{ day.dateString }}</p>
      </div>
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DailyData from '../../models/DailyData.model'

@Component({
  components: { PartouCard }
})
export default class ServiceOfferDashboardHeader extends Vue {
  @Prop({ required: true })
  weekData?: DailyData[]

  @Prop({ required: true })
  selectedDate?: Date

  selectedTabIndex = 0

  @Watch('selectedDate', { immediate: true })
  onSelectedDateChanged () : void {
    const selectedDateString = moment(this.selectedDate).format('DD MMM')
    const tabIndex = this.weekData && this.weekData.findIndex(x => x.dateString === selectedDateString)

    // Set index if it's between 0 (Monday) and 4 (Friday). Otherwise, set it to 0 (Monday)
    this.selectedTabIndex = tabIndex && tabIndex > 0 && tabIndex < 5 ? tabIndex : 0
  }

  onWeekDayClicked (date: Date) : void {
    this.$emit('weekdayClicked', date)
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

  .tabs-container {
    position: relative;
    margin-top: 30px;

    .v-tabs-bar {
      height: 150px;
    }

    .day-wrapper {
      height: 120px;
      display: inline-block;

      &.divider {
        border-right: 1px solid #C4C4C4;
      }
    }
  }

  .category-wrapper {
    color: rgba(0, 0, 0, 0.54);
    display: inline-block;

    .category-waiting-list  {
      font-size: $body-font-size;
      height: 16px;

      i {
        font-size: $body-font-size;
      }
    }

    .category-number  {
      height: 40px;
      font-size: 30px;

      &.text-green {
        color: $partou-green;
      }
    }

    .category-text  {
      width: 50px;
      height: 16px;
      font-size: $body-font-size;
    }
  }

  .day-tab {
    margin-top: 14px;
    text-align: center;
    text-transform: lowercase;
    cursor: pointer;

    .day-tab-text {
      display: block;
      font-size: 30px;
      color: $partou-black !important;
    }

    .day-tab-date {
      display: block;
      font-size: $body-font-size;
    }
  }
</style>

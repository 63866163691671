<template>
  <PartouNotify :width="width" :persistent="persistent || isLoading" :showDialog="showDialog" :scrollable="scrollable" v-on="$listeners" v-bind="$attrs">
    <slot></slot>

    <div class="d-flex flex-wrap">
      <PartouButton v-if="cancelTranslationString" class="mr-8" @click="onNotifyCancel" :disabled="isLoading" :text="$t(cancelTranslationString)" colour="partou-black-ninety"></PartouButton>
      <v-btn ref="partou-dialog-confirm" v-if="confirmTranslationString" @click="onNotifyAccept" :ripple="false" rounded class="confirm-button" :disabled="isConfirmButtonDisabled || isLoading" :loading="isLoading">{{ $t(confirmTranslationString) }}</v-btn>
    </div>
  </PartouNotify>
</template>

<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'

@Component({
  components: { PartouButton, PartouNotify }
})
export default class PartouDialog extends PartouNotify {
  @Prop({ required: false })
  cancelTranslationString?: string

  @Prop({ required: false })
  confirmTranslationString?: string

  @Prop({ required: false, default: false })
  showLoaderOnConfirm!: boolean

  @Prop({ required: false })
  onNotifyAccepted? : () => Promise<void>

  @Prop({ required: false, default: false })
  isConfirmButtonDisabled?: boolean

  isLoading = false

  @Emit('accepted')
  async onNotifyAccept () : Promise<void> {
    this.isLoading = this.showLoaderOnConfirm
    if (this.onNotifyAccepted) {
      try {
        await this.onNotifyAccepted()
      } catch (error) {
        console.error(error)
      }
    }
    this.isLoading = false
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.flex-wrap {
  flex-wrap: wrap !important;
  flex-direction: row !important;
  justify-content: flex-end;
}
.confirm-button {
  color: $partou-primary-white;
  background-color: $partou-primary-salmon !important;
  text-transform: none !important;
}
</style>

<template v-slot:header>
  <PartouCard :hide-actions="true" class="mt-2 full-width">
    <template v-slot:card-body>
      <div class="activations">
        <h1 class="sub-header">{{ $t('services.serviceActivations.title') }}</h1>
        <v-row>
          <v-col class="mr-1">
            <BenefitCalculatorActivation
              v-model="service"
              :activeProducts="activeProducts"
              :schools="schools"
              @input="onServiceActivationsChanged"
            />
            <SelectionGuideActivation
              v-model="service"
              :activeProducts="activeProducts"
              :activeSubscriptions="activeSubscriptions"
              @input="onServiceActivationsChanged"
            />
          </v-col>
          <v-col class="ml-1">
            <WaitingListActivation
              v-model="service"
              @input="onServiceActivationsChanged"
              :canEnableWaitingList="canEnableWaitingList"
            />
            <OffersAndPlacementsActivation
              v-model="service"
              @input="onServiceActivationsChanged"
            />
          </v-col>
        </v-row>
        <v-overlay :value="isLoading">
          <v-progress-circular indeterminate size="100"></v-progress-circular>
        </v-overlay>
      </div>
    </template>
  </PartouCard>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import BenefitCalculatorActivation from './components/BenefitCalculatorActivation.vue'
import WaitingListActivation from './components/WaitingListActivation.vue'
import SelectionGuideActivation from './components/SelectionGuideActivation.vue'
import OffersAndPlacementsActivation from './components/OffersAndPlacementsActivation.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { School, Service, ServiceKind, ServiceProduct, SubscriptionService } from '@/models'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { IProductService } from '@/services/ProductService/IProductService'
import { ISchoolService } from '@/services/SchoolService/ISchoolService'

@Component({
  components: { PartouCard, BenefitCalculatorActivation, WaitingListActivation, SelectionGuideActivation, OffersAndPlacementsActivation }
})
export default class ServiceActivations extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)
  schoolService: ISchoolService = container.get<ISchoolService>(SERVICE_IDENTIFIERS.ISchoolService)
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)

  @Prop({ required: true })
  public entityId!: string

  service?: Service | null = null
  isLoading = false
  useFlexkidsProducts = false
  canEnableWaitingList = false
  activeProducts: ServiceProduct[] = []
  activeSubscriptions: SubscriptionService[] = []
  schools: School[] = []

  @Watch('entityId', { immediate: true })
  async onEntityIdChangedAsync () : Promise<void> {
    this.isLoading = true
    this.service = await this.serviceService.getOneAsync(this.entityId)

    if (!this.service) {
      this.isLoading = false
      return
    }

    this.useFlexkidsProducts = this.service.serviceSettings[0]?.useFlexkidsProducts

    // Get the active products for the service. We use these to determine if there are valid bookable products and if
    // there are products set for use in the child benefit calculator.
    if (this.useFlexkidsProducts) {
      const activeProducts = await this.productService.getActivatedServiceProductsAsync(this.service.id)
      this.activeProducts = activeProducts?.items

    // For subscription based services we need to request active subscriptions, whether waiting list can be enabled and
    // schools to perform various checks that allow toggling settings.
    } else {
      try {
        this.activeSubscriptions = await this.serviceService.getActiveSubscriptionsByServiceIdAsync(this.service.id)
      } catch (err) {
        this.activeSubscriptions = []
      }

      try {
        this.canEnableWaitingList = (await this.serviceService.canEnableWaitingList(this.service)).result
      } catch (err) {
        this.canEnableWaitingList = false
      }

      this.schools = []
      if (this.service.kind === ServiceKind.SchoolCare) {
        this.schools = await this.schoolService.getSchoolsByServiceIdAsync(this.service.id)
      }
    }

    this.isLoading = false
  }

  async onServiceActivationsChanged (newService: Service): Promise<void> {
    this.service = { ...newService }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.activations {
  margin: 16px;
}

h1.sub-header {
  font-size: 24px;
}

.v-overlay {
  border-radius: 24px;
}
</style>

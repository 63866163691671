import { ApolloClient } from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IProductService } from './IProductService'
import {
  ServiceProduct,
  GetHasActivatedServiceProductsQuery,
  GetHasActivatedServiceProductsDocument,
  GetSingleServiceProductQuery,
  GetSingleServiceProductDocument,
  GetDeactivatedServiceProductsDocument,
  GetDeactivatedServiceProductsQuery,
  GetDeactivatedServiceProductsQueryVariables,
  GetActivatedServiceProductsDocument,
  GetActivatedServiceProductsQuery,
  GetActivatedServiceProductsQueryVariables,
  ActivateServiceProductsDocument,
  ActivateServiceProductsMutation,
  DeactivateServiceProductDocument,
  DeactivateServiceProductMutation,
  UpdateServiceProductShowTextualAdditionDocument,
  UpdateServiceProductShowTextualAdditionMutation,
  UpdateServiceProductTextualAdditionDocument,
  UpdateServiceProductTextualAdditionMutation,
  ActivateServiceProductIsUsedInChildBenefitCalculatorMutation,
  ActivateServiceProductIsUsedInChildBenefitCalculatorDocument,
  GetActiveBenefitCalculatorProductsQuery,
  GetActiveBenefitCalculatorProductsDocument,
  DeactivateServiceProductIsUsedInChildBenefitCalculatorMutation,
  DeactivateServiceProductIsUsedInChildBenefitCalculatorDocument,
  GetProductsByServiceQuery,
  GetProductsByServiceDocument,
  GetProductsByServiceQueryVariables,
  Product,
  ServiceVarietyName,
  GetProductsByServiceVarietyQueryVariables,
  GetProductsByServiceVarietyQuery,
  GetProductsByServiceVarietyDocument
} from '@/models'
import Permission from '@/models/enums/Permission'
import _ from 'lodash'
import ServiceProductTableOptions from '@/pages/ServicesPage/ServiceProduct/ServiceProductTable/ServiceProductTableOptions'

@injectable()
export default class ProductService implements IProductService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getProductsByServiceAsync (serviceId: string, serviceVariety?: ServiceVarietyName): Promise<Partial<Product>[]> {
    const variables : GetProductsByServiceQueryVariables = {
      serviceId,
      serviceVariety
    }

    const result = await this.apollo.query<GetProductsByServiceQuery>({
      query: GetProductsByServiceDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetProduct } }
    })

    const products = result?.data.service_product.map(serviceProduct => serviceProduct.product)
    return (products ?? []) as Partial<Product>[]
  }

  async getProductsByServiceVarietyAsync (serviceVariety?: ServiceVarietyName): Promise<Partial<Product>[]> {
    const variables : GetProductsByServiceVarietyQueryVariables = {
      serviceVariety
    }

    const result = await this.apollo.query<GetProductsByServiceVarietyQuery>({
      query: GetProductsByServiceVarietyDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetProduct } }
    })

    return result.data?.product
  }

  async getActivatedServiceProductsAsync (serviceId: string, options?: ServiceProductTableOptions, referenceDate = new Date()): Promise<{ items: ServiceProduct[]; totalCount: number }> {
    const variables : GetActivatedServiceProductsQueryVariables = {
      serviceVarietySearchOn: options?.filters.serviceVariety ? `%${options.filters.serviceVariety}%` : null,
      categorySearchOn: options?.filters.category ? `%${options.filters.category.value}%` : null,
      searchOn: (options?.filters.global && options.filters.global.length > 0) ? `%${options.filters.global}%` : null,
      limit: options?.itemsPerPage,
      offset: options?.itemsPerPage ? options.itemsPerPage * (options.page - 1) : undefined,
      order_by: options?.sortBy?.map((sortBy, index) => _.set({}, sortBy, options.sortDesc[index] ? 'desc' : 'asc')), // eslint-disable-line @typescript-eslint/no-non-null-assertion
      serviceId,
      referenceDate
    }

    const result = await this.apollo.query<GetActivatedServiceProductsQuery>({ // eslint-disable-line @typescript-eslint/no-non-null-assertion
      query: GetActivatedServiceProductsDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetProduct } }
    })

    const items: any[] = result?.data?.service_product // eslint-disable-line @typescript-eslint/no-explicit-any
    const totalCount = result?.data?.service_product_aggregate?.aggregate?.count ?? 0 // eslint-disable-line

    return { items, totalCount }
  }

  async getDeactivatedServiceProductsAsync (serviceId: string, options?: ServiceProductTableOptions, referenceDate = new Date()): Promise<{ items: ServiceProduct[]; totalCount: number }> {
    const variables : GetDeactivatedServiceProductsQueryVariables = {
      serviceVarietySearchOn: options?.filters.serviceVariety ? `%${options.filters.serviceVariety}%` : null,
      categorySearchOn: options?.filters.category ? `%${options.filters.category.value}%` : null,
      searchOn: (options?.filters.global && options.filters.global.length > 0) ? `%${options.filters.global}%` : null,
      limit: options?.itemsPerPage,
      offset: options?.itemsPerPage ? options.itemsPerPage * (options.page - 1) : undefined,
      order_by: options?.sortBy?.map((sortBy, index) => _.set({}, sortBy, options.sortDesc[index] ? 'desc' : 'asc')), // eslint-disable-line @typescript-eslint/no-non-null-assertion
      serviceId,
      referenceDate
    }

    const result = await this.apollo.query<GetDeactivatedServiceProductsQuery>({ // eslint-disable-line @typescript-eslint/no-non-null-assertion
      query: GetDeactivatedServiceProductsDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetProduct } }
    })

    const items: any[] = result?.data?.service_product // eslint-disable-line @typescript-eslint/no-explicit-any
    const totalCount = result?.data?.service_product_aggregate?.aggregate?.count ?? 0 // eslint-disable-line

    return { items, totalCount }
  }

  async getSingleServiceProductAsync (serviceId: string, productId: string): Promise<Partial<ServiceProduct>> {
    const result = await this.apollo.query<GetSingleServiceProductQuery>({
      query: GetSingleServiceProductDocument,
      variables: { serviceId, productId },
      context: { headers: { 'X-Hasura-Role': Permission.GetProduct } }
    })
      .catch(e => console.error(e))
    return result?.data.service_product[0] as Partial<ServiceProduct>
  }

  async getHasActivatedServiceProductsAsync (serviceId: string, referenceDate = new Date()): Promise<boolean> {
    const result = await this.apollo.query<GetHasActivatedServiceProductsQuery>({
      query: GetHasActivatedServiceProductsDocument,
      variables: { serviceId, referenceDate },
      context: { headers: { 'X-Hasura-Role': Permission.GetProduct } }
    })
      .catch(e => console.error(e))
    return result?.data.service_product ? result?.data.service_product.length > 0 : false
  }

  async getActiveBenefitCalculatorProducts (serviceId: string, referenceDate = new Date()): Promise<Partial<ServiceProduct>[]> {
    const result = await this.apollo.query<GetActiveBenefitCalculatorProductsQuery>({
      query: GetActiveBenefitCalculatorProductsDocument,
      variables: { serviceId, referenceDate },
      context: { headers: { 'X-Hasura-Role': Permission.GetProduct } }
    })
      .catch(e => console.error(e))

    return (result?.data.service_product ?? []) as Partial<ServiceProduct>[]
  }

  async ActivateServiceProductsAsync (serviceId: string, productIdsSet: Set<string>): Promise<void> {
    const productIds = Array.from(productIdsSet)

    await this.apollo.mutate<ActivateServiceProductsMutation>({
      mutation: ActivateServiceProductsDocument,
      variables: { serviceId, productIds },
      context: { headers: { 'X-Hasura-Role': Permission.ManageProduct } }
    })
      .catch(e => console.error(e))
  }

  async DeactivateServiceProductAsync (serviceId: string, productId: string): Promise<void> {
    await this.apollo.mutate<DeactivateServiceProductMutation>({
      mutation: DeactivateServiceProductDocument,
      variables: { serviceId, productId },
      context: { headers: { 'X-Hasura-Role': Permission.ManageProduct } }
    })
      .catch(e => console.error(e))
  }

  async ActivateIsUsedInChildBenefitCalculatorAsync (serviceId: string, productId: string): Promise<void> {
    await this.apollo.mutate<ActivateServiceProductIsUsedInChildBenefitCalculatorMutation>({
      mutation: ActivateServiceProductIsUsedInChildBenefitCalculatorDocument,
      variables: { serviceId, productId },
      context: { headers: { 'X-Hasura-Role': Permission.ManageProduct } }
    })
      .catch(e => console.error(e))
  }

  async DeactivateIsUsedInChildBenefitCalculatorForService (serviceId: string, productId?: string): Promise<void> {
    await this.apollo.mutate<DeactivateServiceProductIsUsedInChildBenefitCalculatorMutation>({
      mutation: DeactivateServiceProductIsUsedInChildBenefitCalculatorDocument,
      variables: { serviceId, productId: productId ?? null },
      context: { headers: { 'X-Hasura-Role': Permission.ManageProduct } }
    })
      .catch(e => console.error(e))
  }

  async UpdateServiceProductTextualAdditionAsync (serviceId: string, productId: string, textualAddition: string): Promise<void> {
    await this.apollo.mutate<UpdateServiceProductTextualAdditionMutation>({
      mutation: UpdateServiceProductTextualAdditionDocument,
      variables: { serviceId, productId, textualAddition },
      context: { headers: { 'X-Hasura-Role': Permission.ManageProduct } }
    })
      .catch(e => console.error(e))
  }

  async UpdateServiceProductShowTextualAdditionAsync (serviceId: string, productId: string, showTextualAddition: boolean): Promise<void> {
    await this.apollo.mutate<UpdateServiceProductShowTextualAdditionMutation>({
      mutation: UpdateServiceProductShowTextualAdditionDocument,
      variables: { serviceId, productId, showTextualAddition },
      context: { headers: { 'X-Hasura-Role': Permission.ManageProduct } }
    })
      .catch(e => console.error(e))
  }
}

import 'reflect-metadata'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import ApolloClient, { DefaultOptions } from 'apollo-client'
import axios, { AxiosInstance } from 'axios'
import App from '@/pages/App/App.vue'
import router from '@/router/router'
import i18n from '@/plugins/i18n'
import initVuetify from '@/plugins/vuetify'
import { createStore } from '@/plugins/vuex'
import hasuraService, { HasuraOptions } from '@/services/HasuraService/HasuraService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import '@/utils/filters'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import icons from '@/init/icons'

// Services
import ServiceService from '@/services/ServiceService/ServiceService'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import ServiceVarietyOpeningService from '@/services/ServiceVarietyOpeningService/ServiceVarietyOpeningService'
import { IServiceVarietyOpeningService } from '@/services/ServiceVarietyOpeningService/IServiceVarietyOpeningService'
import ReservationService from '@/services/ReservationService/ReservationService'
import { IReservationService } from '@/services/ReservationService/IReservationService'
import S3UploadService from './services/UploadService/S3UploadService'
import { IUploadService } from './services/UploadService/IUploadService'
import { AuthService } from './services/AuthService/AuthService'
import { IAuthService } from './services/AuthService/IAuthService'
import GroupPincodeService from './services/GroupPincodeService/GroupPincodeService'
import { IGroupPincodeService } from './services/GroupPincodeService/IGroupPincodeService'
import ServiceMediaService from './services/ServiceMediaService/ServiceMediaService'
import { IServiceMediaService } from './services/ServiceMediaService/IServiceMediaService'
import WaitingListService from './services/WaitingListService/WaitingListService'
import { IWaitingListService } from './services/WaitingListService/IWaitingListService'
import BookingService from './services/BookingService/BookingService'
import IBookingService from './services/BookingService/IBookingService'
import ServiceFeatureService from './services/ServiceFeatureService/ServiceFeatureService'
import { IServiceFeatureService } from './services/ServiceFeatureService/IServiceFeatureService'
import PropositionService from './services/PropositionService/PropositionService'
import { IPropositionService } from './services/PropositionService/IPropositionService'
import SchoolService from './services/SchoolService/SchoolService'
import { ISchoolService } from './services/SchoolService/ISchoolService'
import PostalCodeService from './services/PostalCodeService/PostalCodeService'
import IPostalCodeService from './services/PostalCodeService/IPostalCodeService'
import SubscriptionService from './services/SubscriptionService/SubscriptionService'
import { ISubscriptionService } from './services/SubscriptionService/ISubscriptionService'
import OfferService from './services/OfferService/OfferService'
import { IOfferService } from './services/OfferService/IOfferService'
import SendEmailService from './services/SendEmailService/SendEmailService'
import { ISendEmailService } from './services/SendEmailService/ISendEmailService'
import ConfigurationService from './services/ConfigurationService/ConfigurationService'
import { IConfigurationService } from './services/ConfigurationService/IConfigurationService'
import WaitingListPropositionService from './services/WaitingListPropositionService/WaitingListPropositionService'
import { IWaitingListPropositionService } from './services/WaitingListPropositionService/IWaitingListPropositionService'
import ServiceSettingsService from './services/ServiceSettingsService/ServiceSettingsService'
import { IServiceSettingsService } from './services/ServiceSettingsService/IServiceSettingsService'
import ProductService from './services/ProductService/ProductService'
import { IProductService } from './services/ProductService/IProductService'
import { IAuditService } from './features/ServiceAudit/services/IAuditService'
import AuditService from './features/ServiceAudit/services/AuditService'
// Fonts
import '@fontsource/roboto'
import '@mdi/font/css/materialdesignicons.min.css'

Vue.use(VueLodash, { lodash })
Vue.use(VueApollo)

Vue.config.productionTip = false

// Define constants
const HASURA_URI = process.env.VUE_APP_HASURA_HTTP_URI
const authService = new AuthService()

export default class Main {
  async load () : Promise<void> {
    await authService.loginAsync(true)

    this.initServices()
    this.initDependencies()

    this.initSentry()
    this.loadPage(App)
  }

  initSentry (): void {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
      ],
      tracesSampleRate: 0.2,
      environment: process.env.VUE_APP_PROJECT_ENV,
      release: process.env.VUE_APP_VERSION
    })
  }

  loadPage (app: typeof Vue) : void {
    new Vue({
      router,
      vuetify: initVuetify(icons),
      i18n,
      store: createStore(),
      render: h => h(app)
    }).$mount('#app')
  }

  loadStatic (app: typeof Vue) : void {
    // App should only be mounted for development purposes, since web-components won't make use of public/index.html
    if (process.env.NODE_ENV === 'development') {
      new Vue({
        render: h => h(app)
      }).$mount('#app')
    }
  }

  initServices () : void {
    this.initApolloService()
  }

  /**
   * Initializes the apollo service
   */
  initApolloService () : void {
    const authLink = hasuraService.createAuthLink(authService)
    const hasuraOptions : HasuraOptions = {
      url: HASURA_URI ?? '',
      middleware: [authLink]
    }

    const apolloOptions : DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }

    hasuraService.init(hasuraOptions, apolloOptions)
    Vue.mixin({ apolloProvider: hasuraService.getProvider() })
  }

  initDependencies () : void {
    container.bind<AxiosInstance>(SERVICE_IDENTIFIERS.Axios).toConstantValue(axios)
    container.bind(ApolloClient).toConstantValue(hasuraService.apollo)
    container.bind<IAuthService>(SERVICE_IDENTIFIERS.IAuthService).toConstantValue(authService)
    container.bind<IServiceService>(SERVICE_IDENTIFIERS.IServiceService).to(ServiceService)
    container.bind<IServiceVarietyOpeningService>(SERVICE_IDENTIFIERS.IServiceVarietyOpeningService).to(ServiceVarietyOpeningService)
    container.bind<IOfferService>(SERVICE_IDENTIFIERS.IOfferService).to(OfferService)
    container.bind<IReservationService>(SERVICE_IDENTIFIERS.IReservationService).to(ReservationService)
    container.bind<IBookingService>(SERVICE_IDENTIFIERS.IBookingService).to(BookingService)
    container.bind<IUploadService>(SERVICE_IDENTIFIERS.IUploadService).to(S3UploadService)
    container.bind<IGroupPincodeService>(SERVICE_IDENTIFIERS.IGroupPincodeService).to(GroupPincodeService)
    container.bind<IServiceMediaService>(SERVICE_IDENTIFIERS.IServiceMediaService).to(ServiceMediaService)
    container.bind<IWaitingListService>(SERVICE_IDENTIFIERS.IWaitingListService).to(WaitingListService)
    container.bind<IServiceFeatureService>(SERVICE_IDENTIFIERS.IServiceFeatureService).to(ServiceFeatureService)
    container.bind<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService).to(PropositionService)
    container.bind<ISchoolService>(SERVICE_IDENTIFIERS.ISchoolService).to(SchoolService)
    container.bind<IPostalCodeService>(SERVICE_IDENTIFIERS.IPostalCodeService).to(PostalCodeService)
    container.bind<ISubscriptionService>(SERVICE_IDENTIFIERS.ISubscriptionService).to(SubscriptionService)
    container.bind<ISendEmailService>(SERVICE_IDENTIFIERS.ISendEmailService).to(SendEmailService)
    container.bind<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService).to(ConfigurationService)
    container.bind<IWaitingListPropositionService>(SERVICE_IDENTIFIERS.IWaitingListPropositionService).to(WaitingListPropositionService)
    container.bind<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService).to(ServiceSettingsService)
    container.bind<IProductService>(SERVICE_IDENTIFIERS.IProductService).to(ProductService)
    container.bind<IAuditService>(SERVICE_IDENTIFIERS.IAuditService).to(AuditService)
  }
}

new Main().load()

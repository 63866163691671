<template>
  <div class="full-width">
    <EntityDetailContainer ref="entityContainer" :is-saving="isSaving" :is-loaded="isPageLoaded" :edit-enabled="editEnabled" @editClicked="onEditClickedAsync()" @saveClicked="onSaveClickedAsync()" @cancelClicked="onCancelClickedAsync()">
      <template v-slot:header>
        <h1>{{ $t('services.serviceDetail.serviceInformation') }}</h1>
      </template>

      <template v-slot:form>
        <div class="mb-8" v-if="entity">
          <TextFieldRow :disabled="true" v-model="entity.name" name="services.serviceDetail.form.name" />
          <TextAreaFieldRow :disabled="!editEnabled || !canManageService" :rules=[isOptionallyRequired] v-model="entity.description" name="services.serviceDetail.form.description" />
          <TextFieldRow v-if="entity.location" :disabled="true" :value="address(entity.location)" name="services.serviceDetail.form.address" />
          <TextFieldRow :disabled="true" :value="entity.contactInfo && entity.contactInfo.Email" name="services.serviceDetail.form.email" />
          <TextFieldRow :disabled="true" :value="entity.contactInfo && entity.contactInfo.Phone" name="services.serviceDetail.form.phoneNumber" />
        </div>

        <div v-if="entity && entity.serviceVarieties && entity.serviceVarieties.length" class="mb-8">
          <h2 class="red-header">{{$t(editEnabled ? 'services.serviceDetail.openingTimes.openingTimesTitleEdit' : 'services.serviceDetail.openingTimes.openingTimesTitle')}}</h2>
          <ServiceVarietyOpeningSettings v-model="serviceVarietyOpenings" :note="openingTimesNote" :editEnabled="editEnabled"/>
          <h3 class="input-heading">{{$t('services.serviceDetail.openingTimes.additionalInfo')}}</h3>
          <PartouTextArea
            v-model="openingTimesNote"
            class="note-input pa-0 mt-2"
            :noDataText="$t('services.serviceDetail.openingTimes.additionalInfoNoData')"
            :disabled="!editEnabled || !canManageServiceOpeningTimes"
            :maxLength="openingTimesNoteMaxLength" />
        </div>

        <div>
          <h2> {{ $t('services.serviceDetail.photosTitle')}} </h2>
          <div v-if="editEnabled">
            <v-input type="hidden" v-model="currentMediaCount" :rules=[hasAtleastOneMedia] />
            <v-alert v-if="!hasAtleastOneMedia(currentMediaCount)" :icon="false" class="error--text" colored-border type="error">
              <v-icon>$vuetify.icons.partouClose</v-icon>
              {{ $t('services.form.atLeastOneMedia') }}
            </v-alert>
          </div>
          <DragAndDropFiles :rules=[uploadcompletedRule] v-if="showDragAndDrop && entity" :files="entity.serviceMedia" :allowedFiles="allowedFilesForImageUpload" :isDisabled="!editEnabled || !canManageServiceMedia" @onFileAdded="onFileAdded" @onFileDeleted="onFileDeleted" @onUploadCompleted="onIsUploadingChanged(false)" @onUploadStarted="onIsUploadingChanged(true)"></DragAndDropFiles>
        </div>
      </template>
    </EntityDetailContainer>
    <PartouSnackbar v-model="showErrMsg" :timeout="-1">
      {{errorMsg}}
      <template v-slot:action>
        <v-btn text @click="showErrMsg = false">
          {{$t('notification.close')}}
        </v-btn>
      </template>
    </PartouSnackbar>

    <PartouDialog
      :width="412"
      v-if="showDialog"
      :showDialog="showDialog"
      @accepted="onDialogConfirmed"
      @canceled="onDialogCancelled"
      :cancelTranslationString="dialogCancelText"
      :confirmTranslationString="dialogConfirmationText">
        <h3>{{ dialogTitle }} </h3>
        <p class="mb-6 mt-6"> {{ dialogDescription }} </p>
        <ul class="mb-6 mt-6" v-if="dialogErrors.length">
           <li v-for="error in dialogErrors" :key="error">
              {{error}}
            </li>
        </ul>
    </PartouDialog>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import { BaseEntityDetail } from '@/pages/_base'
import EntityDetailContainer from '@/components/EntityDetailContainer'
import TextFieldRow from '@/components/TextFieldRow'
import TextAreaFieldRow from '@/components/TextAreaFieldRow'
import NumberFieldRow from '@/components/NumberFieldRow'
import PartouSnackbar from '@/components/PartouComponents/PartouSnackbar.vue'
import PartouTextArea from '@/components/PartouComponents/PartouTextArea.vue'
import OpeningTimes from '@/components/OpeningTimes'
import DragAndDropFiles from '@/components/DragAndDropFiles/DragAndDropFiles.vue'
import { AllowedFiles } from '@/components/DragAndDropFiles/AllowedFiles'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Service, ServiceMedia, ServicePricing, Location, ServiceVarietyOpening, ServiceVariety } from '@/models'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { IServiceVarietyOpeningService } from '@/services/ServiceVarietyOpeningService/IServiceVarietyOpeningService'
import ServiceVarietyOpeningSettings from './ServiceVarietyOpening/ServiceVarietyOpeningSettings.vue'
import { ServiceVarietyOpeningTimes } from './ServiceVarietyOpening/ServiceVarietyOpeningTimes'
import { isBetween } from '@/utils/dateUtils'
import { IAuthService } from '@/services/AuthService/IAuthService'
import Permission from '@/models/enums/Permission'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import { ROUTES } from '@/router/routes'

type Media = { toDelete: ServiceMedia[], toAdd: File[] }

@Component({
  components: { EntityDetailContainer, TextFieldRow, TextAreaFieldRow, NumberFieldRow, OpeningTimes, PartouTextArea, DragAndDropFiles, PartouSnackbar, PartouNotify, PartouDialog, ServiceVarietyOpeningSettings }
})
export default class ServiceDetail extends BaseEntityDetail<Service> {
  repository: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  serviceSettingsService: IServiceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)
  serviceVarietyOpeningService: IServiceVarietyOpeningService = container.get<IServiceVarietyOpeningService>(SERVICE_IDENTIFIERS.IServiceVarietyOpeningService)

  originalServicePricing?: Partial<ServicePricing>

  allowedFilesForImageUpload = [AllowedFiles.images, AllowedFiles.videos]
  media : Media = { toDelete: [], toAdd: [] }
  showDragAndDrop = false

  showErrMsg = false
  errorMsg = ''
  isUploading = false

  showHasCreatedWaitingListPropositionsDialog = false
  waitingListDialogErrors: Array<string> = []
  hasCreatedWaitingListPropositions = false
  showDialog = false
  dialogErrors: Array<string> = []
  dialogTitle?: string = undefined
  dialogDescription?: string = undefined
  dialogConfirmationText?: string = undefined
  dialogCancelText?: string = undefined
  noActiveProductsDialogType: string | undefined
  serviceVarietyOpenings: ServiceVarietyOpeningTimes[] = []
  openingTimesNote = ''

  get openingTimesNoteMaxLength () : number {
    return 600
  }

  checkActiveOpeningTimesErrors (): void {
    const hasActiveOpeningTime = this.entity!.serviceVarieties.some((x) => { return x.serviceVarietyOpenings.some((y) => { return isBetween(new Date(), y.validFrom, y.validUntil) }) }) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    if (!hasActiveOpeningTime) {
      this.dialogErrors.push(this.$t('services.serviceDetail.dialog.noActiveOpeningTimes').toString())
    }
  }

  onDialogConfirmed (): void {
    this.showDialog = false
    if (!this.entity!.isPublishedForChildBenefitCalculator) { // eslint-disable-line @typescript-eslint/no-non-null-assertion
      this.$router.push({ name: ROUTES.serviceControlVariables, params: { serviceId: this.entityId } })
    }
  }

  onDialogCancelled (): void {
    this.showDialog = false
    this.dialogTitle = undefined
    this.dialogDescription = undefined
    this.dialogConfirmationText = undefined
    this.dialogCancelText = undefined
    this.dialogErrors = []
  }

  onIsUploadingChanged (value : boolean) : void {
    this.isUploading = value
  }

  get canManagePricing () : boolean {
    return this.authService.hasPermission([Permission.ManagePricing])
  }

  get canManageService () : boolean {
    return this.authService.hasPermission([Permission.ManageService])
  }

  get canManageServiceMedia () : boolean {
    return this.authService.hasPermission([Permission.ManageServiceMedia])
  }

  get canManageServiceOpeningTimes ():boolean {
    return this.authService.hasPermission([Permission.ManageServiceOpeningTimes])
  }

  get uploadcompletedRule () : boolean {
    return !this.isUploading
  }

  get address () : (location: Location) => string {
    return (location: Location) => `${location?.addressLine1 ?? ''}, ${location?.addressLine2 ? location?.addressLine2 + ', ' : ''}${location?.postalCode ?? ''}, ${location?.locality ?? ''}` || ''
  }

  get currentMediaCount () : number {
    return (this.entity?.serviceMedia?.length ?? 0) + this.media.toAdd.length - this.media.toDelete.length
  }

  get days () : string[] {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }

  onFileAdded (file : File) : void {
    this.media.toAdd.push(file)
  }

  onFileDeleted (file : File) : void {
    const indexToDelete = this.media.toAdd.findIndex((f : File) => f.name === file.name)
    if (indexToDelete !== -1) {
      this.media.toAdd.splice(indexToDelete, 1)
    }

    const serviceMedia = this.entity?.serviceMedia?.find((serviceMedia : ServiceMedia) => serviceMedia.filename === file.name)
    if (serviceMedia) {
      this.media.toDelete = this.media.toDelete.concat(serviceMedia)
    }
  }

  async onEntityLoadedAsync (entity: Service) : Promise<void> {
    if (entity) {
      const serviceSettings = await this.serviceSettingsService.getServiceSettingsForServiceDetailPageByServiceIdAsync(entity.id)

      this.serviceVarietyOpenings = []
      entity.serviceVarieties?.forEach((x: ServiceVariety) => {
        const openingTimesDefault: ServiceVarietyOpening | undefined = x.serviceVarietyOpenings.find(x => !x.isManuallySet)
        const openingTimesCustom: ServiceVarietyOpening | undefined = x.serviceVarietyOpenings.find(x => x.isManuallySet)
        const openingTimes: ServiceVarietyOpeningTimes = {
          standard: this.mapOpeningTimes(x, openingTimesDefault),
          custom: this.mapOpeningTimes(x, openingTimesCustom),
          serviceVarietyId: x.id,
          serviceVariety: x.name,
          useCustomTimes: !!openingTimesCustom
        }

        this.serviceVarietyOpenings.push(openingTimes)
      })

      this.openingTimesNote = serviceSettings?.openingTimesNote ?? ''
    }

    this._resetDragAndDrop()
  }

  mapOpeningTimes (serviceVariety: ServiceVariety, openingTimes: ServiceVarietyOpening | undefined): Partial<ServiceVarietyOpening> {
    let serviceVarietyOpening: Partial<ServiceVarietyOpening>

    if (openingTimes) {
      serviceVarietyOpening = {
        ...openingTimes,
        serviceVarietyId:
        serviceVariety.id,
        isManuallySet: true
      }
    } else {
      serviceVarietyOpening = {
        type: 'School',
        validFrom: new Date(),
        validUntil: null,
        serviceVarietyId: serviceVariety.id,
        isManuallySet: false,
        monday: [{ From: null, Until: null }],
        tuesday: [{ From: null, Until: null }],
        wednesday: [{ From: null, Until: null }],
        thursday: [{ From: null, Until: null }],
        friday: [{ From: null, Until: null }],
        saturday: [{ From: null, Until: null }],
        sunday: [{ From: null, Until: null }]
      }
    }

    this.days.forEach(day => {
      const dayOpeningHours = serviceVarietyOpening[day as keyof ServiceVarietyOpening]
      if (!dayOpeningHours || dayOpeningHours.length === 0) {
        serviceVarietyOpening[day as keyof ServiceVarietyOpening]?.push({ From: null, Until: null })
      }
    })

    return serviceVarietyOpening
  }

  async updateServiceVarietyOpeningAsync () : Promise<void> {
    for (const serviceVarietyOpening of this.serviceVarietyOpenings) {
      const clonedServiceVarietyOpening = { ...serviceVarietyOpening.custom }

      this.days.forEach(day => {
        if (!clonedServiceVarietyOpening[day as keyof ServiceVarietyOpening][0].From ||
          !clonedServiceVarietyOpening[day as keyof ServiceVarietyOpening][0].Until) {
          clonedServiceVarietyOpening[day as keyof ServiceVarietyOpening] = []
        }
      })

      if (clonedServiceVarietyOpening.id && !serviceVarietyOpening.useCustomTimes) {
        await this.serviceVarietyOpeningService.deleteManualServiceVarietyOpeningIdAsync(clonedServiceVarietyOpening as ServiceVarietyOpening)
        serviceVarietyOpening.custom.id = undefined
      } else if (clonedServiceVarietyOpening.id && serviceVarietyOpening.useCustomTimes) {
        await this.serviceVarietyOpeningService.updateManualServiceVarietyOpeningIdAsync(clonedServiceVarietyOpening as ServiceVarietyOpening)
      } else if (!clonedServiceVarietyOpening.id && serviceVarietyOpening.useCustomTimes) {
        const id = await this.serviceVarietyOpeningService.insertManualServiceVarietyOpeningIdAsync(clonedServiceVarietyOpening as ServiceVarietyOpening)
        serviceVarietyOpening.custom.id = id
      }
    }
  }

  async updateServiceOpeningTimesNoteAsync (serviceId: string) : Promise<void> {
    const note = this.openingTimesNote.trim().substring(0, this.openingTimesNoteMaxLength)
    await this.serviceSettingsService.setOpeningTimesNoteByServiceIdAsync(serviceId, note)
  }

  private _resetDragAndDrop () {
    this.showDragAndDrop = false
    this.media = { toDelete: [], toAdd: [] }
    setTimeout(() => { this.showDragAndDrop = true }, 0)
  }

  async beforeUpsertEntityAsync () : Promise<void> {
    if (this.entityId) {
      if (this.canManageServiceMedia) {
        await this.updateMediaAsync()
      }
    }
  }

  async upsertEntityAsync (entity: Service) : Promise<void> {
    if (entity) {
      if (this.canManageService) {
        await this.repository.upsertOneAsync(entity)
      }

      if (this.canManageServiceOpeningTimes) {
        await this.updateServiceVarietyOpeningAsync()
        await this.updateServiceOpeningTimesNoteAsync(entity.id)
      }
    }
  }

  async onEntityUpsertedAsync () : Promise<void> {
    this.entity = await this.getEntityAsync(this.entityId) // Note: refetch the entity to reset the service pricing with the newest entries
  }

  async updateMediaAsync () : Promise<void> {
    try {
      await this.repository.deleteMediaByServiceIdAsync(this.entityId, this.media.toDelete)

      if (this.entity && this.entity.serviceMedia) {
        const serviceMedia = await this.repository.insertMediaByServiceIdAsync(this.entityId, this.media.toAdd)
        this.entity.serviceMedia.push(...serviceMedia)
      }

      this.media = { toAdd: [], toDelete: [] }
    } catch (error) {
      const errMsg = this.$t('services.serviceDetail.errors.updateMediaFailed').toString()
      this.updateFailed(errMsg)
    }
  }

  hasAtleastOneMedia (value: number): boolean {
    // Field is only required when isPublished is set
    if (!this.entity?.isPublished) {
      return true
    }

    if (!value || value < 0) {
      return false
    }

    return true
  }

  isOptionallyRequired (value: number | string): boolean | string {
    // Field is only required when isPublished is set
    if (!this.entity?.isPublished) {
      return true
    }

    if (!value || value < 0) {
      return this.$t('services.form.isOptionallyRequired').toString()
    }
    return true
  }

  showErrorMessage (message: string) : void {
    this.showErrMsg = true
    this.errorMsg = message
  }

  updateFailed (errMsg: string) : void {
    this.showErrorMessage(errMsg)
    this.isSaving = false
    this.onEditClickedAsync()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

h3.input-heading {
  color: $partou-primary-black-ninety !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
}

.full-width {
  width: 100%;
}

.red-header {
  color: $partou-secondary-bordeaux !important;
  padding-bottom: 10px;
  line-height: 18px;
}

.error--text {
  font-size: 0.875rem !important;
}

.error--text svg {
  width: 0.875rem;
  height: 0.875rem;
  fill: $partou-red;
}

.note-input {
  width: 818px;
  height: 142px;
}
</style>

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter-row{max-height:64px!important}.filter-row .date-filter-row{display:inline-flex;width:auto;padding-top:2px}.filter-row .date-filter-row .date-filter-separator{color:#a5a5a4;padding:0 8px;line-height:50px}.filter-dropdown{width:200px}.filter-dropdown-auto-grow .vs__dropdown-menu{min-width:200px;width:auto!important}.filter-separator{align-content:center}.filter-separator div{border-left:1px solid #a5a5a4;width:1px;height:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partouPrimaryPurple": "#582354",
	"partouPrimaryPurpleSixty": "#9b7b98",
	"partouPrimaryPurpleFourty": "#bca7bb",
	"partouPrimarySalmon": "#ef575d",
	"partouPrimarySalmonSixty": "#f59a9e",
	"partouPrimarySalmonFourty": "#f9bcbe",
	"partouPrimaryOrange": "#f6a500",
	"partouPrimaryOrangeSixty": "#fac966",
	"partouPrimaryOrangeFourty": "#fbdb99",
	"partouPrimaryWarmGrey": "#f2e9e2",
	"partouPrimaryWarmGreySixty": "#f7f2ee",
	"partouPrimaryWarmGreyFourty": "#faf6f3",
	"partouPrimaryWhite": "#fff",
	"partouPrimaryBackdropWhite": "#f9f9f9",
	"partouPrimaryBlue": "#2782e3",
	"partouSecondaryBordeaux": "#b61544",
	"partouSecondaryBordeauxSixty": "#d3738f",
	"partouSecondaryBordeauxFourty": "#e2a1b4",
	"partouSecondaryPeach": "#fa8870",
	"partouSecondaryPeachSixty": "#fcb8a9",
	"partouSecondaryPeachFourty": "#fdcfc6",
	"partouSecondaryGreen": "#91c37d",
	"partouSecondaryGreenSixty": "#bddbb1",
	"partouSecondaryGreenFourty": "#d3e7cb",
	"partouSecondaryBlue": "#73a4d8",
	"partouSecondaryBlueSixty": "#abc8e8",
	"partouSecondaryBlueFourty": "#c7dbef",
	"partouSecondaryBrightGreen": "#3bc059",
	"partouPrimaryGradient": "linear-gradient(45deg,#a02846 13.5%,#e8565d 87%)",
	"partouSecondaryGradient": "linear-gradient(61.41deg,#572556 7.85%,#eb5e5e 64.25%,#f7a70a 91.8%)",
	"partouPrimaryBlack": "#1d1d1b",
	"partouPrimaryBlackNinty": "#343432",
	"partouPrimaryBlackEighty": "#4a4a49",
	"partouPrimaryBlackSeventy": "#616160",
	"partouPrimaryBlackSixty": "#777776",
	"partouPrimaryBlackFifty": "#8e8e8d",
	"partouPrimaryBlackFourty": "#a5a5a4",
	"partouPrimaryBlackThirty": "#bcbcbb"
};
export default ___CSS_LOADER_EXPORT___;

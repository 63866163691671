import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { DayOfWeek, GetControlVariablesByServiceIdDocument, GetControlVariablesByServiceIdQuery, GetDashboardDataByServiceIdDocument, GetDashboardDataByServiceIdQueryVariables, GetGroupPincodesOfGroupDocument, GetGroupPincodesOfGroupQuery, GetServiceByIdDocument, GetServiceByIdQuery, GetServicesDocument, GetServicesQuery, GroupPincode, Service, ServiceMedia, SetFlexMarginByServiceIdDocument, SetFlexMarginByServiceIdMutation, SetFlexMarginByServiceIdMutationVariables, UpdateServiceDocument, UpdateServiceIsPublishedDocument, UpdateServiceIsPublishedMutation, UpdateServiceMutation, CanEnableWaitingListQuery, CanEnableWaitingListDocument, CanEnableWaitingListOutput, EnableWaitingListAutomationMutation, EnableWaitingListAutomationDocument, DisableWaitingListAutomationMutation, DisableWaitingListAutomationDocument, UpdateServiceToggleCustomSubscriptionMutation, UpdateServiceToggleCustomSubscriptionDocument, SubscriptionService, GetActiveSubscriptionsByServiceIdQuery, GetActiveSubscriptionsByServiceIdDocument, UpdateServiceValidateWaitingListPropositionsMutation, UpdateServiceValidateWaitingListPropositionsDocument, HasCreatedWaitingListPropositionsQuery, HasCreatedWaitingListPropositionsDocument, ExportServiceSettingsDocument, ExportServiceSettingsQueryVariables, ServiceKind, GetServiceKindByServiceIdQueryVariables, GetServiceKindByServiceIdDocument } from '@/models'
import moment from 'moment'
import { IUploadService } from '../UploadService/IUploadService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IServiceMediaService } from '../ServiceMediaService/IServiceMediaService'
import { IAuthService } from '../AuthService/IAuthService'
import Permission from '@/models/enums/Permission'
import { startOfUtcDay } from '@/utils/dateUtils'

@injectable()
export default class ServiceService implements IServiceService {
  private apollo: ApolloClient<unknown>
  get authService () : IAuthService { return container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService) }
  get uploadService () : IUploadService { return container.get<IUploadService>(SERVICE_IDENTIFIERS.IUploadService) }
  get serviceMediaService () : IServiceMediaService { return container.get<IServiceMediaService>(SERVICE_IDENTIFIERS.IServiceMediaService) }

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getOneAsync (id: string): Promise<Service> {
    const result = await this.apollo.query<GetServiceByIdQuery>({
      query: GetServiceByIdDocument,
      variables: { id },
      context: { headers: { 'X-Hasura-Role': Permission.GetService } }
    })

    return result.data?.service_by_pk as Service
  }

  public async getAllAsync (): Promise<Service[]> {
    const result = await this.apollo.query<GetServicesQuery>({ query: GetServicesDocument, context: { headers: { 'X-Hasura-Role': Permission.GetService } } })
    return result?.data?.service as Service[] ?? []
  }

  async upsertOneAsync (entity: Service): Promise<Service> {
    return entity.id
      ? this.updateOneAsync(entity)
      : this.insertOneAsync(entity)
  }

  async updateOneAsync (entity: Service): Promise<Service> {
    const { id, description } = entity
    const result = await this.apollo.mutate<UpdateServiceMutation>({
      mutation: UpdateServiceDocument,
      variables: { id, description },
      context: { headers: { 'X-Hasura-Role': Permission.ManageService } }
    })
    return result.data?.update_service_by_pk as Service
  }

  async canEnableWaitingList (entity: Service) : Promise<CanEnableWaitingListOutput> {
    const { id } = entity
    const result = await this.apollo.query<CanEnableWaitingListQuery>({
      query: CanEnableWaitingListDocument,
      variables: { id },
      context: { headers: { 'X-Hasura-Role': Permission.GetWaitingList } }
    })
    return result.data?.canEnableWaitingList as CanEnableWaitingListOutput
  }

  async updateServiceIsPublishedAsync (id: string, isPublished?: boolean, isPublishedForChildBenefitCalculator?: boolean, isBookable?: boolean): Promise<Service> {
    const result = await this.apollo.mutate<UpdateServiceIsPublishedMutation>({
      mutation: UpdateServiceIsPublishedDocument,
      variables: { id, isPublished, isPublishedForChildBenefitCalculator, isBookable },
      context: { headers: { 'X-Hasura-Role': Permission.PublishService } }
    })
    return result.data?.update_service_by_pk as Service
  }

  async updateServiceValidateWaitinglistPropositionAsync (entity: Service): Promise<Service> {
    const { id, validateWaitingListPropositions } = entity
    const result = await this.apollo.mutate<UpdateServiceValidateWaitingListPropositionsMutation>({
      mutation: UpdateServiceValidateWaitingListPropositionsDocument,
      variables: { id, validateWaitingListPropositions },
      context: { headers: { 'X-Hasura-Role': Permission.ManageWaitingList } }
    })
    return result.data?.update_service_by_pk as Service
  }

  async enableWaitingListAutomationAsync (entity: Service): Promise<void> {
    const referenceDate = new Date()
    await this.apollo.mutate<EnableWaitingListAutomationMutation>({
      mutation: EnableWaitingListAutomationDocument,
      variables: {
        serviceId: entity.id,
        referenceDate
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageWaitingList } }
    })
      .catch(e => console.error(e))
  }

  async disableWaitingListAutomationAsync (entity: Service): Promise<void> {
    const referenceDate = new Date()
    await this.apollo.mutate<DisableWaitingListAutomationMutation>({
      mutation: DisableWaitingListAutomationDocument,
      variables: {
        serviceId: entity.id,
        referenceDate
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageWaitingList } }
    })
      .catch(e => console.error(e))
  }

  async toggleCustomSubscriptionAsync (serviceId: string, isCustomSubscriptionEnabled: boolean): Promise<void> {
    await this.apollo.mutate<UpdateServiceToggleCustomSubscriptionMutation>({
      mutation: UpdateServiceToggleCustomSubscriptionDocument,
      variables: {
        id: serviceId,
        isCustomSubscriptionEnabled
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageService } }
    })
      .catch(e => console.error(e))
  }

  async insertOneAsync (entity: Service): Promise<Service> {
    throw new Error(`It is not allowed to insert Services (entity: ${JSON.stringify(entity)}).`)
  }

  deleteOneAsync (id: string): Promise<void> {
    throw new Error(`It is not allowed to delete Services (id: ${id}).`)
  }

  async getControlVariablesByServiceIdAsync (id: string): Promise<Service> {
    const result = await this.apollo.query<GetControlVariablesByServiceIdQuery>({
      query: GetControlVariablesByServiceIdDocument,
      variables: { id },
      context: { headers: { 'X-Hasura-Role': Permission.GetSetting } }
    })
    return result.data.service_by_pk as Service
  }

  async getHasCreatedWaitingListPropositionsAsync (id: string): Promise<boolean> {
    const result = await this.apollo.query<HasCreatedWaitingListPropositionsQuery>({
      query: HasCreatedWaitingListPropositionsDocument,
      variables: { id },
      context: { headers: { 'X-Hasura-Role': Permission.ManageWaitingList } }
    })
    return (result.data.service_aggregate.aggregate?.count ?? -1) > 0
  }

  async getActiveSubscriptionsByServiceIdAsync (id: string): Promise<SubscriptionService[]> {
    const result = await this.apollo.query<GetActiveSubscriptionsByServiceIdQuery>({
      query: GetActiveSubscriptionsByServiceIdDocument,
      variables: { serviceId: id },
      context: { headers: { 'X-Hasura-Role': Permission.ManageService } }
    })
    return result.data.subscription_service as SubscriptionService[]
  }

  public async getGroupPincodesOfGroupByIdAsync (groupId: string, startDate: Date, dayOfWeek: DayOfWeek): Promise<GroupPincode[]> {
    // Make start-date time-independent
    const startDateUtcDate = startOfUtcDay(startDate)

    const result = await this.apollo.query<GetGroupPincodesOfGroupQuery>({
      query: GetGroupPincodesOfGroupDocument,
      variables: { groupId, startDate: startDateUtcDate, dayOfWeek },
      context: { headers: { 'X-Hasura-Role': Permission.ManageOffer } }
    })

    return result.data.group_pincode as GroupPincode[]
  }

  public async getServiceDashboardDataAsync (serviceId : string, startDate: Date) : Promise<Service> {
    const formatString = 'yyyy-MM-DD'
    const mondayDate = moment(startDate).format(formatString)
    const tuesdayDate = moment(startDate).add(1, 'day').format(formatString)
    const wednesdayDate = moment(startDate).add(2, 'day').format(formatString)
    const thursdayDate = moment(startDate).add(3, 'day').format(formatString)
    const fridayDate = moment(startDate).add(4, 'day').format(formatString)
    const saturdayDate = moment(startDate).add(5, 'day').format(formatString)
    const sundayDate = moment(startDate).add(6, 'day').format(formatString)

    const variables: GetDashboardDataByServiceIdQueryVariables = { serviceId, mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate }
    const result = await this.apollo.query({
      query: GetDashboardDataByServiceIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetOffer } }
    })

    return result.data.service_by_pk
  }

  async getServiceKindByServiceIdAsync (serviceId: string): Promise<ServiceKind | null> {
    const variables: GetServiceKindByServiceIdQueryVariables = {
      serviceId
    }

    const result = await this.apollo.query({
      query: GetServiceKindByServiceIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetService } }
    })

    const serviceKind = result.data?.service_by_pk?.kind
    return serviceKind ?? null
  }

  public async exportServiceSettingsAsync () : Promise<string> {
    const variables: ExportServiceSettingsQueryVariables = {}
    const result = await this.apollo.query({
      query: ExportServiceSettingsDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetService } }
    })

    return result.data.exportServiceSettings.base64CsvServiceExport
  }

  async setFlexMarginByServiceIdAsync (variables: SetFlexMarginByServiceIdMutationVariables): Promise<Service> {
    const result = await this.apollo.query<SetFlexMarginByServiceIdMutation>({ query: SetFlexMarginByServiceIdDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageSetting } } })
    return result.data.update_service?.returning[0] as Service
  }

  async insertMediaByServiceIdAsync (serviceId: string, files: File[]): Promise<ServiceMedia[]> {
    return Promise.all(files.map(async (file) => {
      const newFileName = this.getGUID()

      await this.uploadService.uploadOneAsync(newFileName, file, `services/${serviceId}/images`)
      const url = await this.uploadService.getFetchUrl({ objectKey: newFileName, objectKeyPrefix: `services/${serviceId}/images` })
      return this.serviceMediaService.upsertOneAsync({ filename: newFileName, url, mimeType: file.type, serviceId } as ServiceMedia)
    }))
  }

  async deleteMediaByServiceIdAsync (serviceId: string, serviceMedia: ServiceMedia[]): Promise<void> {
    await Promise.all(serviceMedia.map(async (serviceMedium) => {
      if (serviceMedium.filename) {
        await this.uploadService.deleteOneAsync(serviceMedium.filename, `services/${serviceId}/images`)
        await this.serviceMediaService.deleteOneAsync(serviceMedium.id)
      }
    }))
  }

  getGUID () : string {
    let d = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  }
}

<template>
  <ActivationsCard
    ref="allow-direct-placement"
    :title="$t('services.serviceActivations.offersAndPlacements.title')"
    :errorMessage="errorText"
    :disabled="isNotBookable">
    <v-row>
      <v-col class="flex-grow-0 pt-3">
        <v-switch inset
          ref="allow-direct-placement-switch"
          v-model="allowDirectPlacement"
          :disabled="!isAuthorized || isNotBookable"
          @change="updateAllowDirectPlacementAsync"
        />
      </v-col>
      <v-col>
        <h3>{{ $t('services.serviceActivations.offersAndPlacements.enable.title') }}</h3>
        <p>{{ $t('services.serviceActivations.offersAndPlacements.enable.description') }}</p>
      </v-col>
    </v-row>
  </ActivationsCard>
</template>

<script lang="ts">
import ActivationsCard from './ActivationsCard.vue'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Service } from '@/models'
import i18n from '@/plugins/i18n'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { IOfferService } from '@/services/OfferService/IOfferService'
import { IAuthService } from '@/services/AuthService/IAuthService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'

@Component({
  components: { ActivationsCard }
})
export default class OffersAndPlacementsActivation extends Vue {
  serviceSettingsService: IServiceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)
  offerService: IOfferService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  public value!: Service

  get service (): Service {
    return this.value
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.PublishService])
  }

  get allowDirectPlacement (): boolean {
    if (this.service.servicePricings.length) {
      return this.service?.serviceSettings?.[0]?.allowDirectPlacement ?? false
    }

    return false
  }

  set allowDirectPlacement (isEnabled: boolean) {
    if (this.service && this.service.serviceSettings && this.service.servicePricings.length) {
      this.service.serviceSettings[0].allowDirectPlacement = isEnabled
    }
  }

  get isNotBookable (): boolean {
    return !this.service?.isBookable || !this.service?.permitNumber
  }

  get errorText (): string | undefined {
    return this.isAuthorized && this.isNotBookable ? i18n.t('services.serviceActivations.offersAndPlacements.errorText').toString() : undefined
  }

  @Watch('isNotBookable')
  onIsBookableChanged (): void {
    if (this.isNotBookable && this.allowDirectPlacement) {
      this.allowDirectPlacement = false
      this.updateAllowDirectPlacementAsync()
    }
  }

  async updateAllowDirectPlacementAsync (): Promise<void> {
    await this.serviceSettingsService.setAllowDirectPlacementAsync(this.service.id, this.allowDirectPlacement)

    // If allowDirectPlacement is enabled then CapacityChangedOn needs to be set so that the capacities will be calculated again.
    if (this.allowDirectPlacement) {
      await this.offerService.updateCapacityChangedOnForOfferByServiceIdAsync(this.service.id)
    }

    this.$emit('input', this.service)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/switch.scss';

p {
  color: $partou-primary-black-seventy;
}
</style>

import { GetServiceAuditDocument, GetServiceAuditQueryVariables, GetServiceAuditUsersDocument, QueryRoot, ServiceAuditUser } from '@/models'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IAuditService } from '@/features/ServiceAudit/services/IAuditService'
import Permission from '@/models/enums/Permission'
import { ServiceAuditItem, ServiceAuditTableData, ServiceAuditType, ProductChangeAudit } from '@/models/Audit'

@injectable()
export default class AuditService implements IAuditService {
  private readonly apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getServiceAuditAsync (variables: GetServiceAuditQueryVariables): Promise<ServiceAuditTableData> {
    const result = await this.apollo.query<QueryRoot>({
      query: GetServiceAuditDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetAudit } }
    })

    if (!result?.data?.getServiceAudit) {
      return {
        items: [],
        totalCount: 0
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const items = result.data.getServiceAudit.items?.reduce((items: ServiceAuditItem[], auditItemData: any) => {
      const auditType = this.getAuditType(auditItemData?.newData)
      if (auditType === null) {
        return items
      }

      const auditItem: ServiceAuditItem = {
        id: auditItemData?.eventId,
        category: auditItemData.category,
        type: auditType,
        createdAt: auditItemData?.createdAt,
        actor: auditItemData?.actor,
        location: auditItemData?.serviceName,
        changeDetails: this.mapAuditTypeData(auditType, auditItemData)
      }

      items.push(auditItem)
      return items
    }, []) ?? []

    return {
      items,
      totalCount: result.data.getServiceAudit.totalCount ?? 0
    }
  }

  async getServiceAuditUsersAsync (): Promise<ServiceAuditUser[]> {
    const result = await this.apollo.query<QueryRoot>({
      query: GetServiceAuditUsersDocument,
      context: { headers: { 'X-Hasura-Role': Permission.GetAudit } }
    })

    return result.data.getServiceAuditUsers ?? []
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAuditType (newAuditData: any): ServiceAuditType | null {
    if (newAuditData?.is_active !== undefined) {
      return newAuditData?.is_active === 'true' ? ServiceAuditType.ProductActivatedForLocation : ServiceAuditType.ProductDeactivatedForLocation
    } else if (newAuditData?.show_textual_addition !== undefined) {
      return newAuditData?.show_textual_addition === 'true' ? ServiceAuditType.ProductNoteActivated : ServiceAuditType.ProductNoteDeactivated
    } else if (newAuditData?.is_used_in_child_benefit_calculator !== undefined) {
      return newAuditData?.is_used_in_child_benefit_calculator === 'true' ? ServiceAuditType.ProductActivatedForCalculator : ServiceAuditType.ProductDeactivatedForCalculator
    } else if (newAuditData?.textual_addition !== undefined) {
      return ServiceAuditType.ProductNoteChanged
    }

    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapAuditTypeData (auditType: ServiceAuditType, auditData: any): ProductChangeAudit | undefined {
    switch (auditType) {
    case ServiceAuditType.ProductActivatedForLocation:
    case ServiceAuditType.ProductDeactivatedForLocation:
    case ServiceAuditType.ProductActivatedForCalculator:
    case ServiceAuditType.ProductDeactivatedForCalculator:
      return {
        product: auditData?.product?.name,
        serviceVariety: auditData?.product?.serviceVariety
      }

    case ServiceAuditType.ProductNoteActivated:
    case ServiceAuditType.ProductNoteDeactivated:
      return {
        product: auditData?.product?.name,
        serviceVariety: auditData?.product?.serviceVariety,
        showTextualAddition: auditData?.newData?.show_textual_addition === 'true',
        textualAddition: auditData?.originalData?.textual_addition
      }

    case ServiceAuditType.ProductNoteChanged:
      return {
        product: auditData?.product?.name,
        serviceVariety: auditData?.product?.serviceVariety,
        showTextualAddition: auditData?.originalData?.show_textual_addition === 'true',
        textualAddition: auditData?.newData?.textual_addition,
        textualAdditionOld: auditData?.originalData?.textual_addition
      }

    default:
      return undefined
    }
  }
}

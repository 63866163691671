<template>
  <ActivationsCard :title="$t('services.serviceActivations.waitingListManagement.title')">
    <div>
      <v-row>
        <v-col class="flex-grow-0 pt-3">
          <v-switch inset
            ref="waiting-list-actualization-slider"
            v-model="isWaitingListActualizationEnabled"
            :disabled="!isAuthorized || isWaitingListActualizationSliderDisabled"
            @change="updateWaitingListActualizationAsync"
          />
        </v-col>
        <v-col>
          <h3>{{ $t('services.serviceActivations.waitingListManagement.waitingListActualization.title') }}</h3>
          <p>{{ $t('services.serviceActivations.waitingListManagement.waitingListActualization.description') }}</p>
          <p class="error-message" v-if="isAuthorized && isWaitingListActualizationSliderDisabled">{{ $t('services.serviceActivations.waitingListManagement.waitingListActualization.errorText') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="flex-grow-0 pt-3">
          <v-switch inset
            ref="validate-waiting-list-propositions-slider"
            v-model="validateWaitingListPropositions"
            :disabled="!isAuthorized"
            @change="updateValidateWaitingListPropositionsAsync"
          />
        </v-col>
        <v-col>
          <h3>{{ $t('services.serviceActivations.waitingListManagement.waitingListValidation.title') }}</h3>
          <p>{{ $t('services.serviceActivations.waitingListManagement.waitingListValidation.description') }}</p>
        </v-col>
      </v-row>
    </div>

    <PartouDialog
      ref="has-created-waiting-list-propositions-warning"
      v-if="showHasCreatedWaitingListPropositionsDialog"
      :showDialog="showHasCreatedWaitingListPropositionsDialog"
      :width="412"
      @canceled="closeHasCreatedWaitingListPropositionsDialog">
        <h3>{{ $t('services.serviceActivations.waitingListManagement.waitingListValidation.warningDialog.title') }} </h3>
        <p class="mb-6 mt-6"> {{ $t('services.serviceActivations.waitingListManagement.waitingListValidation.warningDialog.text') }} </p>
    </PartouDialog>
  </ActivationsCard>
</template>

<script lang="ts">
import ActivationsCard from './ActivationsCard.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { Service } from '@/models'
import { IAuthService } from '@/services/AuthService/IAuthService'
import Permission from '@/models/enums/Permission'

@Component({
  components: { ActivationsCard, PartouDialog }
})
export default class WaitingListActivation extends Vue {
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  value!: Service

  @Prop({ required: false })
  canEnableWaitingList!: boolean

  showHasCreatedWaitingListPropositionsDialog = false

  get service (): Service {
    return this.value
  }

  get useFlexkidsProducts (): boolean {
    return this.service?.serviceSettings[0]?.useFlexkidsProducts
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.ManageWaitingList])
  }

  get isWaitingListActualizationEnabled (): boolean {
    return !this.service?.waitingListFrozenSince
  }

  set isWaitingListActualizationEnabled (isEnabled: boolean) {
    if (!this.service) {
      return
    }

    if (isEnabled) {
      this.service.waitingListFrozenSince = null
    } else {
      this.service.waitingListFrozenSince = new Date()
    }
  }

  get isWaitingListActualizationSliderDisabled (): boolean {
    if (!this.useFlexkidsProducts) {
      return !this.canEnableWaitingList
    }

    return false
  }

  get validateWaitingListPropositions (): boolean {
    return this.service?.validateWaitingListPropositions
  }

  set validateWaitingListPropositions (isEnabled: boolean) {
    if (this.service) {
      this.service.validateWaitingListPropositions = isEnabled
    }
  }

  async updateWaitingListActualizationAsync (): Promise<void> {
    if (this.isWaitingListActualizationEnabled) {
      await this.serviceService.enableWaitingListAutomationAsync(this.service)
    } else {
      await this.serviceService.disableWaitingListAutomationAsync(this.service)
    }
    this.$emit('input', this.service)
  }

  async updateValidateWaitingListPropositionsAsync (): Promise<void> {
    // When validate waiting list propositions gets disabled check if there are waiting list propositions and if so show
    // a warning.
    if (!this.validateWaitingListPropositions && await this.serviceService.getHasCreatedWaitingListPropositionsAsync(this.service.id)) {
      this.showHasCreatedWaitingListPropositionsDialog = true
    }

    await this.serviceService.updateServiceValidateWaitinglistPropositionAsync(this.service)
    this.$emit('input', this.service)
  }

  closeHasCreatedWaitingListPropositionsDialog (): void {
    this.showHasCreatedWaitingListPropositionsDialog = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/switch.scss';

p {
  color: $partou-primary-black-seventy;
}
</style>

<template>
  <v-card flat
    class="frame mb-8"
    :class="{'frame-disabled': disabled}">
    <div>
      <h2>{{ title }}</h2>
      <p v-if="errorMessage" class="error-message" ref="error-message">{{ errorMessage }}</p>
      <slot></slot>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ActivationsCard extends Vue {
  @Prop({ required: true })
  title!: string

  @Prop({ required: false, default: false })
  disabled!: boolean

  @Prop({ required: false, default: '' })
  errorMessage!: string
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

div::v-deep {
  p {
    margin-bottom: 4px;
  }

  .v-input--selection-controls {
    margin-top: 8px !important;
  }
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 24px;
  padding-bottom: 32px;
  border: solid 1px $partou-primary-black-thirty;
  border-radius: 16px;
}

.frame-disabled {
  background: $partou-primary-backdrop-white;
  border: none;
}
</style>

import { AuditCategoryFilter, AuditServiceVariety, Product, ProductTypeFilter, Service } from './generated/graphql'

export enum ServiceAuditType {
  ProductActivatedForLocation = 'ProductActivatedForLocation',
  ProductDeactivatedForLocation = 'ProductDeactivatedForLocation',
  ProductActivatedForCalculator = 'ProductActivatedForCalculator',
  ProductDeactivatedForCalculator = 'ProductDeactivatedForCalculator',
  ProductNoteActivated = 'ProductNoteActivated',
  ProductNoteDeactivated = 'ProductNoteDeactivated',
  ProductNoteChanged = 'ProductNoteChanged'
}

export type ProductSubFilterSettings = {
  changeType: ProductTypeFilter | null
  serviceVariety: AuditServiceVariety | null
  product: Partial<Product> | null
}

export type ServiceAuditFilterSettings = {
  service: Service | null
  category: AuditCategoryFilter | null
  categorySubFilter?: ProductSubFilterSettings
  actor: string | null
  startDate: Date | null
  endDate: Date | null
}

export type ProductChangeAudit = {
  product: string
  serviceVariety: AuditServiceVariety
  showTextualAddition?: boolean
  textualAddition?: string
  textualAdditionOld?: string
}

export type ServiceAuditItem = {
  id: string
  category: AuditCategoryFilter
  type: ServiceAuditType
  createdAt: Date
  changeDetails?: ProductChangeAudit
  location: string
  actor: string
}

export type ServiceAuditTableData = {
  items: ServiceAuditItem[],
  totalCount: number
}

<template>
  <ActivationsCard :title="$t('services.serviceActivations.benefitCalculator.title')">
    <v-row>
      <v-col class="flex-grow-0 pt-3">
        <v-switch inset
          ref="benefit-calculator-enabled-switch"
          v-model="isPublishedForChildBenefitCalculator"
          :disabled="!isAuthorized || isBenefitCalculatorSliderDisabled"
          @change="updateIsPublishedForChildBenefitCalculatorAsync"
        />
      </v-col>
      <v-col>
        <h3>{{ $t('services.serviceActivations.benefitCalculator.enable.title') }}</h3>
        <p>{{ $t('services.serviceActivations.benefitCalculator.enable.description') }}</p>
        <p class="error-message" v-if="isAuthorized && isBenefitCalculatorSliderDisabled">{{ $t('services.serviceActivations.benefitCalculator.enable.errorText') }}</p>
      </v-col>
    </v-row>
  </ActivationsCard>
</template>

<script lang="ts">
import ActivationsCard from './ActivationsCard.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ProductCategory, School, SchoolGroup, SchoolGroupOpening, Service, ServiceKind, ServiceProduct, ServiceVarietyName } from '@/models'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { isBetween } from '@/utils/dateUtils'
import { IAuthService } from '@/services/AuthService/IAuthService'
import Permission from '@/models/enums/Permission'

@Component({
  components: { ActivationsCard }
})
export default class BenefitCalculatorActivation extends Vue {
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  value!: Service

  @Prop({ required: false })
  activeProducts!: ServiceProduct[]

  @Prop({ required: false })
  schools!: School[]

  get service (): Service {
    return this.value
  }

  get useFlexkidsProducts (): boolean {
    return this.service?.serviceSettings[0]?.useFlexkidsProducts
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.PublishService])
  }

  get isPublishedForChildBenefitCalculator (): boolean {
    return this.service?.isPublishedForChildBenefitCalculator
  }

  set isPublishedForChildBenefitCalculator (isEnabled: boolean) {
    if (this.service) {
      this.service.isPublishedForChildBenefitCalculator = isEnabled
    }
  }

  get isBenefitCalculatorSliderDisabled (): boolean {
    if (this.useFlexkidsProducts) {
      return !this.hasValidProducts()
    }

    return !this.hasValidSubscription()
  }

  async updateIsPublishedForChildBenefitCalculatorAsync (): Promise<void> {
    await this.serviceService.updateServiceIsPublishedAsync(
      this.service.id,
      this.service.isPublished,
      this.service.isPublishedForChildBenefitCalculator,
      this.service.isBookable
    )
    this.$emit('input', this.value)
  }

  hasValidProducts (): boolean {
    if (!this.useFlexkidsProducts || !this.activeProducts?.length) {
      return false
    }

    // Get products that are set for use in the child benefit calculator, make sure there is at least one.
    const activeBenefitCalculatorProducts = this.activeProducts.filter(p => p.isUsedInChildBenefitCalculator === true)
    if (activeBenefitCalculatorProducts.length === 0) {
      return false
    }

    // For day care make sure we have an all weeks product set.
    if (this.service.kind === ServiceKind.DayCare &&
      activeBenefitCalculatorProducts.some(p => p.product?.serviceVariety === ServiceVarietyName.KDV && p.product.category === ProductCategory.AllWeeks)) {
      return true
    }

    // For school care make sure we have an NSO product set and if a VSO product is set make sure it is a school weeks
    // product.
    if (this.service.kind === ServiceKind.SchoolCare) {
      const hasVSO: boolean = activeBenefitCalculatorProducts.some(p => p.product?.serviceVariety === ServiceVarietyName.VSO)
      const hasValidVSO: boolean = activeBenefitCalculatorProducts.some(p => p.product?.serviceVariety === ServiceVarietyName.VSO && p.product?.category === ProductCategory.SchoolWeeks)
      const hasValidNSO: boolean = activeBenefitCalculatorProducts.some(p => p.product?.serviceVariety === ServiceVarietyName.NSO)

      if (hasValidNSO && (!hasVSO || hasValidVSO)) {
        return true
      }
    }

    return false
  }

  hasValidSubscription (): boolean {
    // Make sure there is a currently active service price and opening time.
    const now = new Date()
    const hasValidPrice = this.service?.servicePricings.some(x => isBetween(now, x.validFrom, x.validUntil))
    const hasValidOpeningTime = this.service?.serviceVarieties.some((x) =>
      x.serviceVarietyOpenings.some(y => isBetween(now, y.validFrom, y.validUntil))
    )

    if (!hasValidPrice || !hasValidOpeningTime) {
      return false
    }

    // For school care make sure we have a school with at least one schoolGroup which has group opening times that are
    // valid given the current date.
    if (this.service.kind === ServiceKind.SchoolCare) {
      if (!this.schools?.length) {
        return false
      }

      const now = new Date()
      return this.schools.some((school: School) => {
        if (!school.schoolGroups?.length) {
          return false
        }

        return school.schoolGroups.some((schoolGroup: SchoolGroup) => {
          if (!schoolGroup.schoolGroupOpenings?.length) {
            return false
          }

          return schoolGroup.schoolGroupOpenings.some((schoolGroupOpening: SchoolGroupOpening) => {
            return isBetween(now, schoolGroupOpening.validFrom, schoolGroupOpening.validUntil)
          })
        })
      })
    }

    return true
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/switch.scss';

p {
  color: $partou-primary-black-seventy;
}
</style>

<template v-if="showCancelDialog">
  <PartouDialog
    class="cancel-proposition-dialog"
    :width="600"
    :isConfirmButtonDisabled="invalidCausedByReason()"
    :showDialog="showCancelDialog"
    :showLoaderOnConfirm="true"
    :onNotifyAccepted="onNotifyAccepted"
    :cancelTranslationString="$t('waitingListPropositions.table.expanded.cancelHandling')"
    :confirmTranslationString="$t('waitingListPropositions.table.expanded.confirmCancelButton')"
    @canceled="onPropositionActionCancelled"
  >
    <h3 class="bordeaux-red mb-4">
      {{ $t('waitingListPropositions.table.expanded.cancelDialogTitle') }}
    </h3>
    <p v-html="$t('waitingListPropositions.table.expanded.cancelCheck', { name: `${currentProposition.locationInquiry.child.firstName} ${currentProposition.locationInquiry.child.lastName}` })" />
    <p class="mb-4">
      {{ $t('waitingListPropositions.table.expanded.cancelReason') }}
    </p>
    <PartouAutocomplete
      class="cancel-reason-options rounded-xl borderless mb-2"
      :items="cancelReasons"
      :value="causedByReasonType"
      item-text="name"
      item-value="id"
      label="name"
      :appendIconString="'$vuetify.icons.partouChevronDown'"
      :clearable="false"
      :placeholder="$t('causedByReasonPicker.placeholder')"
      :noDataText="$t('servicePicker.noData')"
      @input="onCausedByReasonTypeChanged"
    />
    <PartouTextArea
      v-model="causedByReasonComment"
      class="cancel-reason-textarea"
      :noDataText="$t('causedByReasonPicker.causedByReasonCommentPlaceholder')"
      :clearable="false"
      :maxLength="commentMaxLength"
      @input="onCausedByReasonCommentChanged"
    />
    <div class="button-error-container">
      <span v-if="plannerActionError" class="error-message">
        {{ $t('services.controlVariables.triggerCalculateCapacityError') }}
      </span>
    </div>
  </PartouDialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import PartouTextArea from '@/components/PartouComponents/PartouTextArea.vue'
import { WaitingListProposition } from '@/models'

@Component({
  components: {
    PartouDialog,
    PartouAutocomplete,
    PartouTextArea
  }
})
export default class CancelPropositionDialog extends Vue {
  @Prop({ required: true })
  showCancelDialog!: boolean

  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  @Prop({ required: true })
  cancelReasons!: Array<string>

  @Prop({ required: true })
  commentMaxLength!: number

  @Prop({ required: false, default: false })
  plannerActionError!: boolean

  @Prop({ required: true })
  OnWaitinglistPropositionCancelledAsync? : () => Promise<void>

  causedByReasonType = ''
  causedByReasonComment = ''

  invalidCausedByReason () : boolean {
    return this.causedByReasonType === '' || this.causedByReasonComment.trim().length > this.commentMaxLength
  }

  @Emit('canceled')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPropositionActionCancelled () {}

  @Emit('causedByReasonTypeChanged')
  onCausedByReasonTypeChanged (newReasonType: string) : string {
    this.causedByReasonType = newReasonType
    return newReasonType
  }

  @Emit('causedByReasonCommentChanged')
  onCausedByReasonCommentChanged (newReasonComment: string) : string {
    this.causedByReasonComment = newReasonComment
    return newReasonComment
  }

  async onNotifyAccepted () : Promise<void> {
    if (this.OnWaitinglistPropositionCancelledAsync) {
      await this.OnWaitinglistPropositionCancelledAsync()
    }
  }
}
</script>

<style scoped>
.bordeaux-red {
  color: #7b3b3b !important;
}

.button-error-container {
  display: flex;
  line-break: 16px;

  .error-message {
    padding-bottom: 5px;
  }
}

.cancel-reason-options {
  .vs--searchable {
    .vs__dropdown-menu {
      .vs__dropdown-option {
        white-space: initial !important;
        overflow: unset !important;
        text-overflow: unset !important;
        height: unset !important;
      }
    }
  }
}
</style>
